<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>{{ 'FOOTER_FEED_TITTLE' | translate }}</h4>
								<p>{{ 'FOOTER_FEED_SUBTITTLE' | translate }}</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="{{'INFORM_EMAIL_PLACEHOLDER' | translate}}">
							</div>
							<button type="submit" class="btn btn-solid">{{'BUTTON_SUBSCRIBE' | translate}}</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>{{'TITTLE_FOOTER_UNDERLOGO' | translate}} </p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>

				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>{{ 'TITTLE_FOOTER_SECTION_CATEGORY' | translate}}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">{{ 'TITTLE_FOOTER_SECTION_CATEGORY_1' | translate }}</a></li>
                                <li><a href="javascript:void(0)">{{ 'TITTLE_FOOTER_SECTION_CATEGORY_2' | translate }}</a></li>
                                <li><a href="javascript:void(0)">{{ 'TITTLE_FOOTER_SECTION_CATEGORY_3' | translate }}</a></li>
                                <li><a href="javascript:void(0)">{{ 'TITTLE_FOOTER_SECTION_CATEGORY_4' | translate }}</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>{{ 'TITTLE_FOOTER_SECTION_WHYUS' | translate }}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">{{ 'TITTLE_FOOTER_SECTION_WHYUS_1' | translate }}</a></li>
                                <li><a href="javascript:void(0)">{{ 'TITTLE_FOOTER_SECTION_WHYUS_2' | translate }}</a></li>
                                <li><a href="javascript:void(0)">{{ 'TITTLE_FOOTER_SECTION_WHYUS_3' | translate }}</a></li>
                                <li><a href="javascript:void(0)">{{ 'TITTLE_FOOTER_SECTION_WHYUS_4' | translate }}</a></li>
                                <li><a href="javascript:void(0)">{{ 'TITTLE_FOOTER_SECTION_WHYUS_5' | translate }}</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>{{ 'TITTLE_FOOTER_SECTION_OURDATA' | translate }}:</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>{{ companyInfo.companyName }}, {{ companyInfo.companyAddress }}</li>
						        <li><i class="fa fa-phone"></i>{{ companyInfo.companyPhone }}</li>
						        <li><i class="fa fa-envelope-o"></i><a>{{ companyInfo.companyEmail }}</a></li>
						    </ul>
						</div>
					</div>
				</div>

			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} {{ companyInfo.companyName }}</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->