import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;

  constructor(
    public authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    ) { }


  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

  ngOnInit(): void {
    
    this.authService.authenticationStateChanged.subscribe((isAuthenticated: boolean) => {

      if(isAuthenticated){
        this.toastr.success('Welcome back :)');
        this.router.navigate(['/pages/dashboard']);

      }else{
        this.toastr.success('See you soon :)');
        this.authService.clearUser();
        this.router.navigate(['/']);
      }

    });

  }

  logout(){
    this.authService.logout();
  }

}
