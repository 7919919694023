import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagInputComponent),
      multi: true
    }
  ]
})
export class TagInputComponent implements ControlValueAccessor {

  @Input() separator: string = ',';
  @Input() tags: string[] = [];

  inputValue = '';

  private onChange: any = () => {};
  private onTouched: any = () => {};

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === this.separator) {
      event.preventDefault();
      const tagValue = this.inputValue.trim();
      if (tagValue && !this.tags.includes(tagValue)) {
        this.tags.push(tagValue);
        this.updateValue();
        this.inputValue = '';
        
      }
    }
  }

  deleteTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index !== -1) {
      this.tags.splice(index, 1);
      this.updateValue();
    }
  }

  private updateValue(): void {
    this.onChange(this.tags);
    this.onTouched();
  }

  writeValue(value: any): void {
    if (value) {
      this.tags = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}