import { Injectable, EventEmitter } from '@angular/core';
import { CompanyInfo } from '../classes/CompanyInfo';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { User } from '../classes/user';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authenticationStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  public companyInfo: CompanyInfo = environment;
  public apiUrl: string = this.companyInfo.apiEndpoint + "/auth";
  
  private userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public user$: Observable<User | null> = this.userSubject.asObservable();


  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private toastrService: ToastrService
  ) {
  }

  public login(credentials: any) {
    this.http.post<{ accessToken: string }>(this.apiUrl + '/login', credentials).subscribe({
      next: (response) => {
        this.authenticationStateChanged.emit(true);
      },
      error: (error) => {
        console.error(error);
        this.toastrService.error('Login failed', 'Authentication Error');
        this.authenticationStateChanged.emit(false);
      }
    });
  }

  public register(userData: User){

    return this.http.post<{ accessToken: string }>(this.apiUrl + '/register', userData).pipe(map(data=>{
      if(data.accessToken){
        this.authenticationStateChanged.emit(true);
      }
    }))

  }


  public getUserDetail(): Observable<User> {
    return this.http.get<User>(this.apiUrl + '/validate').pipe(
      map(response => {
        this.setUser(response)
        return response;
      })
    );
  }


  public updateUserDetail(user: User): Observable<User> {
    return this.http.patch<User>(this.apiUrl + '/update', user)
      .pipe(
        map(response => {

          this.setUser(response)
          return response;
        })
      );
  }


  public setUser(user: User): void {
    this.userSubject.next(user);
  }
 
  public clearUser(): void {
    this.http.post(this.apiUrl + '/logout', {}).subscribe({
      next: (response) => {
        console.log(response);
      },
      error: (error) => {
        console.error(error);
        this.toastrService.error('Logout Failled?', 'Authentication Error');
      }
    });
  }
  
  public logout(): void {
    this.authenticationStateChanged.emit(false);
  }

}