// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domain: 'e-com-microservice-0e008ecb66a0.herokuapp.com',
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
  apiEndpoint: 'https://e-com-microservice-0e008ecb66a0.herokuapp.com',
  companyName: 'Demo e-commerce',
  companyAddress: 'Rua a nº 1, Salvador - BA',
  companyPhone: '(71) 99999-9999',
  companyEmail: 'contato@lojadev.dev.br',
  pagseguro_redirect_uri: 'https://webhook.site/6d3c7ea3-aa19-44be-96c9-505c3f5eb924',
  pagseguro_notification_uri: 'https://webhook.site/6d3c7ea3-aa19-44be-96c9-505c3f5eb924'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
